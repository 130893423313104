import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
/**
 * This component is used to display FormControl validation errors below a form control in a user readable format.
 */
@Component({
  selector: 'ngk-form-addon-validation-messages',
  templateUrl: './form-addon-validation-messages.component.html',
  styleUrls: ['./form-addon-validation-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAddonValidationMessagesComponent implements OnChanges {
  /**
   * The ID of the form control to which this component is attached. Required for accessibility.
   */
  @Input() controlId?: string;

  /**
   * Input for FormControl.errors. Required.
   */
  @Input() errors?: ValidationErrors | null;

  /**
   * Input for FormControl.touched. Required.
   */
  @Input() touched: boolean = false;

  /**
   * Input for FormControl.dirty. Required.
   */
  @Input() dirty: boolean = false;

  /**
   * Input for FormControl.invalid. Required.
   */
  @Input() invalid: boolean = false;

  validationMessages: string[] = [];

  errorContainerId: string | null = null;

  private readonly VALIDATION_MESSAGES: { [key: string]: string } = {
    required: 'This field is required.',
    notOnlyWhitespace: 'This field must have more than just spaces or tabs.',
    notOnlyASingleRepeatedCharacter: 'This field must have more than a single repeated character.',
    onlyBasicLatinOrLatin1: 'This field cannot have special characters.',
    maxlength: 'This field has too many characters.',
    minlength: 'This field has not enough characters.',
    email: 'This is not a valid email address.',
    date: 'The input given is not a valid date.',
    maxFileCount: 'There are too many files selected.',
    maxFileSize: 'A selected file is too large.',
    minValue: 'This field is too low.',
    maxValue: 'This field is too high.',
    minLength: 'This field has not enough characters.',
    maxLength: 'This field has too many characters.',
    maxDaysInPast: 'This field is out of the acceptable range.',
    maxDaysInFuture: 'This field is out of the acceptable range.',
    maxYearsInPast: 'This field is out of the acceptable range.',
    maxYearsInFuture: 'This field is out of the acceptable range.',
    formattedTelephoneNumber: 'This phone number is not valid.',
    creditCardNumberInvalid: 'The credit card number is not valid.',
    creditCardNameInvalid: "The cardholder's name is not valid.",
    creditCardDateInvalid: 'The expiration date is not valid.',
    creditCardCVVInvalid: 'The CVV is not valid.',
    creditCardZipInvalid: 'The billing zip code is not valid.',
    atLeastOneRequiredTrue: 'At least one item must be selected.',
    default: 'This field has incorrect data.',
    validEmailWithDomain: 'This is not a valid email address',
  };

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.errors) {
      const errors = Object.keys(this.errors ?? []);
      this.validationMessages = errors
        .map((key) => this.VALIDATION_MESSAGES[key])
        .filter((message) => message != null);
      const missingMessages = errors.filter((key) => this.VALIDATION_MESSAGES[key] == null);
      if (missingMessages.length > 0) {
        console.warn(`Message(s) for ${missingMessages.join(', ')} was not found.`);
        this.validationMessages.push(this.VALIDATION_MESSAGES.default);
      }
    }
    if (changes.controlId) {
      if (this.controlId == null) {
        this.errorContainerId = null;
      } else {
        this.errorContainerId = `${this.controlId}__errors`;
      }
    }
  }
}
