import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { HelpText } from './models/question';
import { AutocompleteOption } from './models/form-elements/autocomplete';
import { RadioOptionsAttributes } from './models/form-elements/radio';
import { SelectOption } from './models/form-elements/select';
import { ToggleOptionAttributes } from './models/form-elements/toggle';
import { PageBuilderOptions } from './page-builder.component';

@Injectable()
export class PageBuilderService {
  options: BehaviorSubject<PageBuilderOptions> = new BehaviorSubject({
    removeSubmit: false,
    buttonText: 'Submit',
  });

  routerSubscription: Subscription;

  userSubscription: Subscription;

  constructor() {}

  manipulateOptions(options: Partial<PageBuilderOptions>) {
    this.options.next({ ...this.options.value, ...options });
  }

  formatSelectOptions(options: any[], value?: string): SelectOption[] {
    return options.flatMap((opt) => [
      { text: opt.label, value: opt.value, selected: opt.value === value },
    ]);
  }

  formatToggleOptions(options: any[]): ToggleOptionAttributes[] {
    // TODO this default is duplicated from the model at toggle.ts
    if (!options || !options.length)
      return [
        { text: 'On', value: true },
        { text: 'Off', value: false },
      ];
    return options.map((opt) => ({ text: opt.label, value: opt.value }));
  }

  // TODO: Clean up Attributes interfaces
  formatRadioOptions(options: any[], value?: unknown): RadioOptionsAttributes[] {
    return options.flatMap((opt) => [
      {
        text: opt.label,
        value: opt.value,
        description: opt.description,
        // eslint-disable-next-line no-nested-ternary
        image: opt.images ? opt.images[0] : opt.image ? { name: opt.image, type: 'icon' } : null, // TODO refactor here and remove [0] and second ternary to support multiple image for one option, ie, 'list' type
        checked: this.evaluateValue(value, opt.value),
        helpText: this.formatHelpText(opt.help_text),
      } as RadioOptionsAttributes,
    ]);
  }

  formatAutocompleteOptions(options: any[], priorityOptions?: any[]): AutocompleteOption[] {
    return options.flatMap((opt) => {
      const isPriority = priorityOptions?.some(
        (priorityOption) => priorityOption[0] === opt[0] && priorityOption[1] === opt[1]
      );
      return [
        {
          text: opt.label,
          value: opt.value,
          priority: isPriority,
        },
      ];
    });
  }

  formatHelpText(helpText: any): HelpText | null {
    if (helpText === undefined) {
      return undefined;
    }
    return {
      text: helpText.text,
      icon: helpText.icon,
      type: helpText.help_type,
    };
  }

  private evaluateValue(value: unknown, toCompare: unknown): boolean {
    // TODO: Probably an easier way to do this! But dealing with types in JS is fun.
    if (String(value) === String(toCompare)) {
      return true;
    }
    return false;
  }
}
