import { SplitIOService } from 'lib-kinponents';
import { Observable, catchError, filter, of, take, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GuidService } from '../../shared/services/guid.service';
import { FullstoryService } from '../../shared/services/fullstory.service';

/**
 * App Initializer factory
 * configures application wide properties only determined at runtime
 * details: https://angular.io/api/core/APP_INITIALIZER
 *
 * @returns a callback function
 *
 * !!! this factory is run before the application starts
 * !!! be careful how much logic is added here and if any of it is blocking
 */
export function appInitializerFactory(
  splitIoService: SplitIOService,
  guidService: GuidService,
  fullStoryService: FullstoryService
): () => Observable<any> {
  return () => {
    /**
     * bootstrap the split.io client and save all tests and treatments in memory.
     * This could be done passively, but waiting for split to load allows us to
     * react to treatments right away, important if the test is on the start page or related
     * to startup app logic to avoid any flickering.
     */
    splitIoService.bootstrapClient({
      splitIoClientKey: environment.splitIoClientKey,
      userIdentifier: guidService.guid,
    });
    fullStoryService.initialize();

    return splitIoService.clientStatus$.pipe(
      tap((status) => {
        if (status === 'failed') {
          // @todo - we should log to RUM here so we know split if failing to load up
          console.warn('Split.io failed to initialize, loading without it');
        }
      }),
      filter((status) => status === 'ready' || status === 'failed'),
      take(1),
      catchError(() => of(true))
    );
  };
}
