import { Injectable } from '@angular/core';
import { datadogLogs as datadog } from '@datadog/browser-logs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  public initialize() {
    if (environment.datadog) {
      datadog.init({
        ...environment.datadog,
        forwardErrorsToLogs: true,
        sampleRate: 100,
      });
    }
  }
}
