export const environment = {
  apiPrefix: '/api/v1',
  baseUrl: 'https://app.kin.haus',
  environmentName: 'staging',
  fullstoryId: 'YVGQ5',
  gtmId: null,
  production: false,
  datadog: {
    clientToken: 'pub231f8dd21f80128cac10b1194a7c73ec',
    site: 'datadoghq.com',
    service: 'ng-kin-staging',
  },
  splitIoClientKey: 'jg52msmphbtu6qhk9p3jj9vdm6mn6vve2dfn',
  smartyKey: '17390636537911911',
};
