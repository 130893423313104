import { SplitFactory } from '@splitsoftware/splitio';
import SplitIO from '@splitsoftware/splitio/types/splitio';

interface SplitInitFactoryArgs {
  splitIoClientKey: string | undefined;
  userIdentifier: string | undefined;
  maxWaitTime?: number;
  splitFactory?: any;
}

/**
 * Create SplitIO Client
 * - will return null if waiting for longer than 4 seconds
 * - promise should always resolve to keep things moving, this is meant to be passive
 * @param splitIoClientKey splitIO client key
 * @param userCookieValue user id or a cookie uuid for anonymous users
 * @param maxWaitTime maximum time we will wait for split.io to load - in seconds
 * @returns promise with client ID or null.
 */
export class CreateSplitIOClient {
  public static createClient({
    splitIoClientKey,
    userIdentifier,
    maxWaitTime = 2,
    splitFactory = SplitFactory,
  }: SplitInitFactoryArgs): Promise<SplitIO.IClient | null> {
    return new Promise<SplitIO.IClient | null>((resolve) => {
      if (splitIoClientKey && userIdentifier) {
        // configure split.io - https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK
        const factory = splitFactory({
          core: {
            authorizationKey: splitIoClientKey,
            key: userIdentifier,
          },
          startup: {
            readyTimeout: maxWaitTime,
          },
        });

        // create new client
        const client: SplitIO.IClient = factory.client();

        // wait for split.io client to start up or timeout
        client.on(client.Event.SDK_READY, () => {
          resolve(client);
        });
        client.on(client.Event.SDK_READY_TIMED_OUT, () => {
          resolve(null);
        });
      } else {
        resolve(null);
      }
    });
  }
}
